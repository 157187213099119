<template>
  <div>
    
    <!-- Table of family members -->

    <!-- Family details modal when editing a row in the table -->

    <FormPageFamilyDetailsModal 
      ref="family-details-modal"
      :item="selected_family_member"
    />

    <v-row class="mt-4" v-if="form_page">
      <v-col lg="12">

        <div class="justify-content-end d-flex mb-4">
          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="create_family_member_clicked"><i
            class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('PUBLIC.FORM.CREATE_FAMILY_MEMBER') }}</a>
        </div>

        <b-table id="forms-page-table" 
          :fields="headers" 
          :items="items"
          head-variant="light" 
          sticky-header 
          class="mh-100 table-striped">

          <template #cell(actions)="row">
            <div class="justify-content-end d-flex">

              <a href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="select_family_member_clicked(row.item.id)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                </span>
              </a>

              <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_family_member_clicked(row.item.id)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                </span>
              </a>
            </div>
          </template>
        </b-table>
        
      </v-col>
    </v-row>

    
    <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.FORM.MISSING_FIELDS') }}</p>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
      <b-button
        v-if="form_page.index != 0"
        class="mt-4 mr-4"
        variant="outline-secondary"
        @click.prevent="on_back"
      >
        {{ $t('COMMON.BACK') }}
      </b-button>
      
      <b-button
        class="mt-4"
        variant="primary"
        :type="type"
        :disabled="disabled || spinning"
        @click.prevent="on_click"
      >
        <memlist-spinner v-if="spinning" />
        {{ $t('COMMON.CONTINUE') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import FormPageFamilyDetailsModal from './FormPageFamilyDetailsModal.vue';


export default {
  name: 'FormPageFamily',
  mixins: [ toasts ],
  components: {
    FormPageFamilyDetailsModal
  },

  props: {
    
    form_page_data: { /* data for the entire form, previously entered, all pages */
      type: Object,
      default: () => ({})
    },
    form_page: { /* page definition for this page, ml_form_page */
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      selected_family_member: null,
      local_form_page_data: {},

      skip: false,

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {

    console.log('FormPagePersonnr mounted() form_page', this.form_page);
    console.log('FormPagePersonnr mounted() form_page_data', this.form_page_data);

    this.local_form_page_data = { ...this.form_page_data };
  },
  watch: {
    form_page_data: {
      handler(newValue) {
        this.local_form_page_data = { ...this.form_page_data }
      },
      deep: true
    }
  },
  methods: {
    create_family_member_clicked() {},

    select_family_member_clicked(id) {},

    delete_family_member_clicked(id) {},

    on_back() {
      this.$emit('back', this.form_page, this.local_form_page_data);
    },

    skip_clicked() {},

    update_form(field_name, value) {
      this.local_form_page_data[field_name] = value;

      this.local_form_page_data = { ...this.local_form_page_data };
    },
    validated(field_name, value, valid) {
      this.local_form_page_data[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_click() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        if (this.is_form_valid) {
          this.spinning = false;

          this.$emit('next', this.form_page, this.local_form_page_data);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

  }
};
</script>
