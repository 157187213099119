<template>
  <div>
    
    <v-card v-if="loading" style="min-width: 400px; max-width: 78px;" class="mx-auto mt-16 mb-32">
      <div class="col-12 text-center">
        <div class="d-flex justify-content-center mb-3 mt-8">
          <memlist-spinner />
        </div>
      </div>

      <div class="mx-auto pb-8">
        <p style="white-space: pre; text-align: center;"><span class="text-center" style="font-weight: bold;">{{ $t('PUBLIC.FORM.PLEASE_WAIT') }}</span></p>

        <p style="text-align: center;" >{{ $t('PUBLIC.FORM.PLEASE_WAIT_INFO') }}</p>
      </div>
  
    </v-card>

    <v-card style="min-width: 400px; max-width: 640px;" class="mx-auto mt-16 mb-32" v-if="!form_create_error && form_done">
      <div class="col-12 text-center">
        <i class="fa fa-check-circle" style="font-size: 42px; color: #8dda8d;"></i>
      </div>

      <div class="mx-auto pb-8">
        <p style="white-space: pre; text-align: center;"><span class="text-center" style="font-weight: bold;">{{ form.texts.success_header }}</span></p>

        <p style="text-align: center;" >{{ form.texts.success_text }}</p>
      </div>
  
    </v-card>


    <v-card style="min-width: 400px; max-width: 640px;" class="mx-auto mt-16 mb-32" v-if="form_create_error">
      <div class="col-12 text-center">
        <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
      </div>

      <div class="mx-auto pb-8">
        <p style="white-space: pre; text-align: center;"><span class="text-center" style="font-weight: bold;">{{ form.texts.failure_header }}</span></p>

        <p style="text-align: center;" >{{ form.texts.failure_text }}</p>
      </div>
  
    </v-card>


    <v-row class="mx-auto" v-if="!loading && show_form_pages" style="max-width: 800px;">
      <v-col align-self="center">
        <v-sheet class="pa-2 ma-2">

          <FormPage 

            ref="form-page"
            :form_data="form_data"
            :form_page="current_form_page" 
            :form_pages="form.pages"
            @next="next_step"
            @confirmed="confirmed"
            @back="back"
            @change="changed"
          />
      
        </v-sheet>
      </v-col>
    </v-row>

    

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';

import FormPage from '@/view/pages/public/forms/FormPage.vue';

export default {
  name: 'FormIndex',
  mixins: [ toasts ],

  components: {
    FormPage
  },

  data() {
    return {

      linkstr: null,

      /// resumed form data
      form_public_store: null,

      error: false,
      show_form_pages: true,

      loading: true,
      form_done: false,
      form_create_error: false,

      current_index: 0,
      
      form_data: [], /// <- the user input data

      current_form_page: {},

      form: { },
      step: 0,

      visitor_id: null,
      company_ids_from_rules: []
    };
  },
  async mounted() {
    this.linkstr = this.$route.params.linkstr;
    /// await this.get_form(linkstr);

    const result = await this.get_form(this.linkstr);

    if (!result) {
      this.error = true;

      return;
    }

    this.form = result;
    this.visitor_id = result.visitor_id;

    for (const page of this.form.pages) {
      this.form_data.push({
        type: page.type,
      });
    }

    // assign the predefined company_ids from rules
    const company_ids = result.form_rules.filter(item => item.type === 'ASSIGN_COMPANY').map(item => item.company_id);

    this.company_ids_from_rules = company_ids;

    setKeyValue('form_company_ids', company_ids);

    this.current_form_page = this.form.pages[this.current_index];

    if (this.current_form_page.type === 'PARENTS') {
      this.current_index++;
      this.current_form_page = this.form.pages[this.current_index];
    }

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  watch: {
    
  },
  methods: {

    async post_form_done() {
      try {
        const res = await axios.post(`/form/public/${this.linkstr}`, { form_data: this.form_data, visitor_id: this.visitor_id });

        if (res.status === 201) {
          return true;
        }
      }
      catch (err) {
        console.error('post_form_done error', err);
      }

      return false;
    },

    changed(form_page, page_data) {

      // calculate the age if personnr or personal details
      if (form_page.type === 'PERSONNR') {

      }

      // when the COMPANY_SELECT page has selected the companies.
      // make sure we add the company selection from rules as well.
      if (form_page.type === 'COMPANY_SELECT') {
        const company_ids = page_data.company_ids;
        for (const id of this.company_ids_from_rules) {
          if (!company_ids.find(company_id => company_id === id)) {
            company_ids.push(id);
          }
        }

        setKeyValue('form_company_ids', company_ids);
      }

      console.log('FormIndex.changed()', form_page, page_data);
      this.form_data[form_page.index] = { ...page_data };

      this.form_data = [ ...this.form_data ];
    },

    back(form_page, page_data) {
      this.current_index = form_page.index - 1;
      this.current_form_page = this.form.pages[this.current_index];

      if (this.current_form_page.type === 'PARENTS') {
        this.current_index--;
        this.current_form_page = this.form.pages[this.current_index];
      }

      this.form_data[form_page.index] = { ...page_data, type: form_page.type };
    },

    /// form is confirmed
    async confirmed(form_page, page_data) {
      this.loading = true;
      this.show_form_pages = false;

      this.$nextTick(async ()=>{
        try {
          this.form_data[form_page.index] = { ...page_data, type: form_page.type };
          this.form_data = [ ...this.form_data ];

          this.form_done = true;
          this.show_form_pages = false;

          const result = await this.post_form_done();

          this.form_create_error = !result;
        }
        catch (err) {
          console.error('confirmed error', err);
          this.form_create_error = true;
        }
        
        this.loading = false;
      });

    },
    
    async next_step(form_page, page_data) {
      this.loading = true;

      window.scrollTo(0,0);

      try {
        this.form_data[form_page.index] = { ...page_data, type: form_page.type };
        
        this.current_index = this.current_index + 1;
        this.current_form_page = this.form.pages[this.current_index];

        this.form_data = [ ...this.form_data ];

        /// stupid Vue doesnt update!!!
        //this.$refs['form-page'].force_update_form_data(this.form_data);

      }
      catch (err) {
        console.error(err);
      }

      this.loading = false;

    },

    async get_form(linkstr) {
      try {
        const res = await axios.get(`/form/${linkstr}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('get_form error', err);
      }

      return null;
    }

  }
};
</script>
