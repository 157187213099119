<template>
  <div>
    
    <div v-if="form_page.questions && form_page.questions.length > 0">
      <div v-for="(question, i) in form_page.questions" :key="i">
        
        <AttributeGroupQuestion
          :property_group="question"
          :force_validate="force_validate"
          :form_data="local_form_page_data"
          :index="i"
          @selected="selected_answer"
        />
      </div>
    </div>
    
    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
      <b-button
        v-if="form_page.index != 0"
        class="mt-4 mr-4"
        variant="outline-secondary"
        @click.prevent="on_back"
      >
        {{ $t('COMMON.BACK') }}
      </b-button>
      
      <b-button
        class="mt-4"
        variant="primary"
        :type="type"
        :disabled="disabled || spinning"
        @click.prevent="on_click"
      >
        <memlist-spinner v-if="spinning" />
        {{ $t('COMMON.CONTINUE') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import AttributeGroupQuestion from '@/view/components/attribute_forms/AttributeGroupQuestion.vue';

export default {
  name: 'FormPageQuestions',
  mixins: [ toasts ],

  components: {
    AttributeGroupQuestion
  },

  props: {
    
    form_page_data: { /* data for the entire form, previously entered, all pages */
      type: Object,
      default: () => ({})
    },
    form_page: { /* page definition for this page, ml_form_page */
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      local_form_page_data: {},

      skip: false,

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {

    console.log('FormPageQuestions mounted() form_page', this.form_page);
    console.log('FormPageQuestions mounted() form_page_data', this.form_page_data);

    this.local_form_page_data = { ...this.form_page_data }

  },
  watch: {
    form_page_data: {
      handler(newValue) {
        
        this.local_form_page_data = { ...this.form_page_data }
      },
      deep: true
    }
  },
  methods: {

    on_back() {
      this.$emit('back', this.form_page, this.local_form_page_data);
    },

    /*
      const selected_group = {
        group_id: this.local_property_group.group_id,
        selected_attributes: selection
      }; */
    selected_answer(selection) {

      console.log('answer selected', selection, this.local_form_page_data);

      if (!this.local_form_page_data) {
        return;
      }

      if (!this.local_form_page_data.selected_answers) {
        this.local_form_page_data.selected_answers = [];
      }

      const group = this.local_form_page_data.selected_answers.find(a => a.group_id === selection.group_id);

      if (!group) {
        this.local_form_page_data.selected_answers.push(selection);
      }
      else {
        group.selected_attributes = selection.selected_attributes;
      }
    },

    skip_clicked() {},

    update_form(field_name, value) {
      this.local_form_page_data[field_name] = value;

      this.local_form_page_data = { ...this.local_form_page_data };
    },
    validated(field_name, value, valid) {
      this.local_form_page_data[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_click() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        if (this.is_form_valid) {
          this.spinning = false;

          this.$emit('next', this.form_page, this.local_form_page_data);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

  }
};
</script>
